import { useState, useEffect, useCallback } from 'react'
import {
  createSepaWallet,
  getAccountReference,
  getPaymentDetails,
  tenantToPrefix,
} from '../api'
import _find from 'lodash/find'
import { fromAxios } from '../utils'
import './SEPA.css'
import sepaLogo from '../sepa-lastschrift-vector-logo-vertical-adjust.svg'
import TextField from './TextField'
import Checkbox from './Checkbox'
import SepaContractText from './SepaContractText'
import CenteredSpinner from './CenteredSpinner'
import IBAN from "iban";

const ibanPatternForGermany = 'DE(\\d){20}'

const wnOrigins = [
  'https://abo-test.wn.de',
  'https://develop.aschendorff.cosmoshop.net',
  'https://master.aschendorff.cosmoshop.net',
  'https://wn-varnish-asc-dev.fp-non-prod.cloud.netcetera.com',
  'https://abo.wn.de',
  'https://wn.aschendorff.cosmoshop.net',
]

const mzOrigins = [
  'https://abo-test.mz.ms',
  'https://abo-test.muensterschezeitung.de',
  'https://mz-varnish-asc-dev.fp-non-prod.cloud.netcetera.com',
  'https://abo.mz.ms',
  'https://abo.muensterschezeitung.de',
  'https://mz.aschendorff.cosmoshop.net',
]

const wbOrigins = [
  'https://abo-test.westfalen-blatt.de',
  'https://abo.westfalen-blatt.de',
  'https://wb.aschendorff.cosmoshop.net',
]

const validPostMessageSenderOrigins = [
  // used only when dev (localhost)
  ...(process.env.NODE_ENV !== 'production' ? ['http://localhost:3000'] : []),

  ...wnOrigins,
  ...mzOrigins,
  ...wbOrigins,
]

/**
 * Finds the latest sepa mandate for tenant based on a given prefix
 * @param {String} prefix on which we base the search for the sepa mandate
 * @returns Single SEPA mandate object containing it's info
 */
const findPaymentDetails = prefix => paymentDetails => paymentDetails.mandateReference.startsWith(prefix)

const SEPA = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [userData, setUserData] = useState({
    sepaExists: false,
    sessionId: '',
    accountReference: '',
    mandateReference: '',
  })
  const setUserDataFor = (name, value) =>
    setUserData(userData => ({
      ...userData,
      [name]: value,
    }))
  const [formValues, setFormValues] = useState({
    pristine: true,
    accountHolderName: '',
    iban: '',
    sepaMandateAccepted: false,
  })
  // a condition of communicating w/ aboshop is that we've received a message from them
  const [receivedPostMessageEvent, setReceivedPostMessageEvent] = useState()
  const handlePostMessage = event => {
    if (!validPostMessageSenderOrigins.includes(event.origin)) {
      console.error(`This domain: ${event.origin} isn't a whitelisted domain.`)
      if (event.source) {
        event.source.postMessage(
          {
            message: `This domain: ${event.origin} isn't a whitelisted domain.`,
          },
          event.origin,
        )
      }
      return
    }
    setReceivedPostMessageEvent(event)
  }
  useEffect(() => {
    window.addEventListener('message', handlePostMessage)
    return () => window.removeEventListener('message', handlePostMessage)
  }, [])

  const validPostMessageConditions = useCallback(() => {
    const { source, origin } = receivedPostMessageEvent
    const localhostTestCase = origin !== 'null'
    if (source && origin && localhostTestCase) {
      return true
    }
    console.error(
      'Missing source or target for postMessage',
      process.env.NODE_ENV !== 'production' && '(ignore for local testing)',
    )
  }, [receivedPostMessageEvent])

  const confirmToAboshopThatSepaExists = (mandateReference) => {
    if (validPostMessageConditions()) {
      console.log('notifying aboshop that sepa exists')
      const { source, origin } = receivedPostMessageEvent
      source.postMessage({ message: 'Customer has SEPA wallet', mandateReference }, origin)
    }
  }

  const passErrorToAboshop = useCallback(
    error => {
      console.error(fromAxios(error) ? error.toJSON() : error)
      if (validPostMessageConditions()) {
        console.log('notifying aboshop that error occurred')
        const { source, origin } = receivedPostMessageEvent
        source.postMessage({ error, message: 'An error occurred!' }, origin)
      }
    },
    [receivedPostMessageEvent, validPostMessageConditions],
  )

  const tenant = useCallback(() => {
    if (process.env.REACT_APP_TEST_IFRAME_WITHOUT_PARENT) {
      return 'asc'
    }
    if (validPostMessageConditions()) {
      const { origin } = receivedPostMessageEvent
      if (wnOrigins.includes(origin)) return 'asc'
      if (wbOrigins.includes(origin)) return 'wb'
      if (mzOrigins.includes(origin)) return 'mz'
      if (process.env.REACT_APP_TEST_IFRAME_WITHOUT_PARENT) return 'asc'
    }
    return
  },
    [receivedPostMessageEvent, validPostMessageConditions]
  )

  useEffect(() => {
    ;(async () => {
      const searchParams = new URLSearchParams(window.location.search)
      const sessionId = searchParams.get('sessionId')
      if (
        sessionId &&
        (process.env.REACT_APP_TEST_IFRAME_WITHOUT_PARENT ||
          receivedPostMessageEvent)
      ) {
        setUserDataFor('sessionId', sessionId)
        try {
          const accountReference = await getAccountReference(sessionId)
          setUserDataFor('accountReference', accountReference)
          const paymentDetails = await getPaymentDetails({
            accountReference,
            sessionId,
          })
          const sepa = paymentDetails.sepa && _find(paymentDetails.sepa, 
            findPaymentDetails(tenantToPrefix[tenant()]))
          if (sepa) {
            setUserDataFor('sepaExists', true)
            const mandateReference = sepa.mandateReference
            setUserDataFor('mandateReference', mandateReference)
            setFormValues(formValues => ({
              ...formValues,
              pristine: true,
              accountHolderName: sepa.accountHolderName,
              iban: sepa.iban,
            }))
          }
        } catch (error) {
          passErrorToAboshop(error)
        }
        setIsLoading(false)
      } else {
        console.log('Session id and a post message are required.')
      }
    })()
  }, [passErrorToAboshop, receivedPostMessageEvent, tenant])

  /**
   * If a user focuses on a pristine prefilled form we assume intent of editing.
   * Since the form is edited a new wallet will be created, so;
   * - when the edit target is 'accountHolderName' reset both iban and accountHolderName
   * - when the edit target is 'iban' reset only iban
   *
   * @param event
   */
  const textInputFocusHandler = event =>
    formValues.pristine &&
    setFormValues({
      ...formValues,
      pristine: false,
      accountHolderName:
        event.target.name === 'iban' ? formValues.accountHolderName : '',
      iban: '',
    })

  const textInputChangeHandler = event =>
    setFormValues(formValues => ({
      ...formValues,
      pristine: false,
      [event.target.name]: event.target.value,
    }))

  const ibanInputChangeHandler = event => {
    if (event.target.validity.patternMismatch || !IBAN.isValid(event.target.value)) {
      event.target.setCustomValidity('Bitte geben Sie eine gültige deutsche IBAN ein.')
    } else {
      event.target.setCustomValidity('')
    }

    setFormValues(formValues => ({
      ...formValues,
      pristine: false,
      iban: event.target.value,
    }))
  }

  const checkboxInputChangeHandler = event =>
    setFormValues(formValues => ({
      ...formValues,
      [event.target.name]: event.target.checked,
    }))

  const submitHandler = async event => {
    event.preventDefault()
    console.log('submitting form')

    if (userData.sepaExists && formValues.pristine) {
      confirmToAboshopThatSepaExists(userData.mandateReference)
    } else {
      try {
        setIsLoading(true)
        const response = await createSepaWallet({
          accountReference: userData.accountReference,
          sessionId: userData.sessionId,
          walletInformation: {
            iban: formValues.iban,
            accountHolderName: formValues.accountHolderName,
          },
          tenant: tenant(),
        })
        console.log('sepa wallet created', response)
        
        const {mandateReference} = response
        setUserDataFor('mandateReference', mandateReference)
        confirmToAboshopThatSepaExists(mandateReference)
      } catch (error) {
        passErrorToAboshop(error)
      }
      setIsLoading(false)
    }
  }

  return (
    <div className="sepa-container p-3">
      {isLoading ? (
        <CenteredSpinner />
      ) : (
        <form onSubmit={submitHandler}>
          <img
            src={sepaLogo}
            className="row sepa-logo ms-0 mb-3"
            alt="SEPA Lastschrifft Logo"
          />
          <TextField
            displayName="Kontoinhaber"
            name="accountHolderName"
            placeholder="Peter Müller"
            value={formValues.accountHolderName}
            onFocus={textInputFocusHandler}
            onChange={textInputChangeHandler}
            required
          />
          <TextField
            displayName="IBAN"
            name="iban"
            placeholder="DE********************"
            value={formValues.iban}
            onFocus={textInputFocusHandler}
            onChange={ibanInputChangeHandler}
            pattern={!formValues.pristine ? ibanPatternForGermany : undefined}
            required
          />
          <Checkbox
            displayName="SEPA-Lastschriftmandat"
            name="sepaMandateAccepted"
            required
            onChange={checkboxInputChangeHandler}
          />
          <SepaContractText />
          <p>* Pflichtfeld</p>
          <div className="col-12 d-flex justify-content-end">
            <button className="btn btn-confirm me-2" type="submit">
              Speichern
            </button>
          </div>
          {process.env.NODE_ENV !== 'production' && (
            <>
              <hr />
              <code>{JSON.stringify(formValues)}</code>
              <hr />
              <code>{JSON.stringify(userData)}</code>
            </>
          )}
        </form>
      )}
    </div>
  )
}

export default SEPA
