const SepaContractText = () => (
  <div>
    <p className="ms-3 text-muted">
      Ich ermächtige meinen Vertragspartner Zahlungen von meinem Konto mittels
      SEPA-Lastschrift einzuziehen. Zugleich weise ich mein Kreditinstitut an,
      die von meinem Vertragspartner auf mein Konto gezogenen Lastschriften
      einzulösen.
    </p>
    <p className="ms-3 text-muted">
      Hinweis zum SEPA-Lastschriftmandat: Ich kann innerhalb von acht Wochen,
      beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrages
      verlangen. Es gelten dabei die mit meinem Kreditinstitut vereinbarten
      Bedingungen.
    </p>
    <p className="ms-3 text-muted">
      Die Mandatsreferenz wird separat mitgeteilt.
    </p>
  </div>
)

export default SepaContractText
