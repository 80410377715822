const CenteredSpinner = () => (
  <div className="text-center">
    <div className="vh-100">
      <div
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <div className="spinner-border">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </div>
)

export default CenteredSpinner
