import { id } from '../utils'

const TextField = ({
  displayName,
  name,
  placeholder,
  value,
  onChange,
  onFocus,
  pattern, // don't add regex delimiters (/) and start/end notation (^/$) - https://stackoverflow.com/a/66313643
  required,
}) => {
  return (
    <div className="col-12 col-mwd-6 mb-3">
      <label htmlFor={id(name)} className="form-label">
        {displayName}
        {required && ' *'}
      </label>
      <input
        type="text"
        className="form-control"
        id={id(name)}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        pattern={pattern}
        required
      />
    </div>
  )
}

export default TextField
