import * as axios from 'axios'
import { environment } from '../environment'
import { padStart } from 'lodash'

const baseURL =
  environment() === 'production'
    ? 'https://eu1-api.mppglobal.com/rest'
    : 'https://eu1-api-uat.mppglobal.com/rest'
const tokenId =
  environment() === 'production'
    ? '6D55F83BF17F4505AADD95387446AACF'
    : 'F30A8FE10A074150ABE8AEE07B0B3F25'

const mpp = axios.create({
  baseURL,
  timeout: 10000,
  headers: {
    'X-Version': '11.17.0',
    'X-TokenId': tokenId,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

/**
 * Prefix is used to update the mandate reference with the tenant
 */
const tenantToPrefix = {
  asc: 'WN_',
  mz: 'MZ_',
  wb: 'WB_',
}

const untilTwelveChars = str => padStart(str, 12, '0')

/**
 * Returns the accountReference given a sessionId.
 * (we expect the sessionId as a URL param to the SEPA iFrame)
 *
 * API ref: https://uat.mppglobal.com/swagger/ui/index#!/Sessions/Sessions_GetSessionStatusForClientV11
 * @param sessionId
 * @returns {Promise<*>}
 * @throws {AxiosError}
 */
const getAccountReference = async sessionId => {
  if (!sessionId) throw new Error('No sessionId provided')
  try {
    const response = await mpp.get('/api/sessions', {
      headers: { 'X-Sessionid': sessionId },
    })
    return response.data.accountReference
  } catch (e) {
    if (e.response && e.response.status === 403) {
      throw new Error('Invalid or expired sessionId provided')
    }
  }
}

/**
 * Returns an object with all existing payment options for a given account,
 * active and inactive.
 *
 * Example response:
 *    {
 *      "cards": [],
 *      "payPal": {
 *        "paymentDetailReference": "001PDE4ZWN58Q5YD73"
 *      },
 *      "sepa": {
 *        "accountHolderName": "Peter Müller",
 *        "iban": "********************5864",
 *        "bic": "RLNWATWWXXX", // present if provided during wallet creation
 *        "contractReference": "0010CJKDYJGABPGY32", // present if provided during wallet creation
 *        "mandateReference": "224606",
 *        "bankCountry": "AT",
 *        "sepaMandateCreationDate": "2022-05-05T13:18:50.053"
 *      }
 *    }
 *
 * API ref: https://uat.mppglobal.com/swagger/ui/index#!/Payment_Details/PaymentDetails_GetPaymentAccountsV10_6
 *
 * @param accountReference ex. 0010AT98PZ4OD7PUG3
 * @param sessionId
 * @returns {Promise<*>}
 * @throws {AxiosError}
 */
const getPaymentDetails = async ({ accountReference, sessionId }) => {
  if (!sessionId) throw new Error('No sessionId provided')
  if (!accountReference) throw new Error('No account reference provided')
  const response = await mpp.get(
    `api/accounts/${accountReference}/payment-details?includeInactiveWallets=true`,
    {
      headers: { 'X-Sessionid': sessionId },
    },
  )
  return response.data
}

/**
 * Provided the usual accountReference plus sessionId also
 * IBAN and an accountHolderName, creates a SEPA wallet.
 * Accepts some optional wallet creation parameters.
 * Also modifies the mandate reference to contain the tenant as prefix.
 *
 * @param accountReference
 * @param sessionId
 * @param walletInformation
 * @param walletInformation.iban
 * @param walletInformation.accountHolderName
 * @param [walletInformation.bic]
 * @param [walletInformation.contractReference]
 * @param [walletInformation.mandateReference]
 * @param [walletInformation.updateExistingMandate]
 * @returns {Promise<*>}
 * @throws {AxiosError}
 */
const createSepaWallet = async ({
  accountReference,
  sessionId,
  walletInformation,
  tenant,
}) => {
  if (!sessionId) throw new Error('No sessionId provided')
  const { iban, accountHolderName } = walletInformation
  if (!iban && !accountHolderName) {
    throw new Error(
      "Missing iban and/or accountHolderName, can't create SEPA wallet",
    )
  }
  const response = await mpp.post(
    `api/accounts/${accountReference}/payment-details/sepa`,
    walletInformation, // req body
    {
      headers: {
        'X-Sessionid': sessionId,
      },
    },
  )

  const prefix = tenantToPrefix[tenant]
  if (!prefix) return response.data // identical to what getPaymentDetails().sepa would return

  const { mandateReference } = response.data
  const modifiedWalltetInformation = { ...walletInformation, mandateReference: `${prefix}${untilTwelveChars(mandateReference)}_n` }

  const modifiedResponse = await mpp.post(
    `api/accounts/${accountReference}/payment-details/sepa`,
    modifiedWalltetInformation, // req body
    {
      headers: {
        'X-Sessionid': sessionId,
      },
    },
  )

  return modifiedResponse.data // identical to what getPaymentDetails().sepa would return
}

export { getAccountReference, getPaymentDetails, createSepaWallet, tenantToPrefix }
