/**
 * This solution was copied from bz-aboshop-web-payment-app.
 * See here for that implementation: https://github.com/forward-distribution/bz-aboshop-web-payment-app/blob/master/src/environment.js
 * See here for some details: https://github.com/forward-distribution/asc-payment/pull/1#discussion_r875826982
 */

export const environment = () => {
  switch (window.location.hostname) {
    case 'aschendorff.esuite-skins.com':
      return 'production'
    case 'localhost':
      return 'local'
    default:
      return 'development'
  }
}
