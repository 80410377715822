import { id } from '../utils'

const Checkbox = ({ displayName, name, onChange, required }) => {
  return (
    <div className="form-check mb-3">
      <input
        className="form-check-input"
        type="checkbox"
        name={name}
        id={id(name)}
        onChange={onChange}
        required
      />
      <label className="form-check-label" htmlFor={id(name)}>
        {displayName}
        {required && ' *'}
      </label>
    </div>
  )
}

export default Checkbox
